import * as React from "react"

import referencesJson from "../../pages/references.json"
import DefaultHead from "../components/layout/head";

// markup
const ReferencesPage = ({pageContext}) => {
    const {lang,pageViewCount=0} = pageContext
    console.log('pageViewCount',pageViewCount);
    const firstLoadDelay = pageViewCount > 1 ? 0 : 4.250;
    const firstSpeedDelay = pageViewCount > 1 ? .25 : .5;
    const topics = referencesJson[lang].topics || []
    return (
  <div className={"page-references"}>
      <div className="list">
        {topics.map((topic,key)=>{
          return (
              <div key={key} className={`ref`}>
                  <h2 className="ref-title" style={{animationDelay:`${firstLoadDelay+(key+.5)*firstSpeedDelay}s`}}>{topic.title}</h2>
                  <p className="ref-text" dangerouslySetInnerHTML={{__html:topic.p}} style={{animationDelay:`${firstLoadDelay+(key+.75)*firstSpeedDelay}s`}} />
                  <ul className="ref-partners" style={{animationDelay:`${firstLoadDelay+(key+1)*firstSpeedDelay}s`}}>
                      {(topic.refs||'').split('\n').map((partner,key)=><li key={key}>{partner}</li>)}
                  </ul>
              </div>
          )
        })}
      </div>
  </div>
  )
}

export default ReferencesPage

export const Head = DefaultHead
